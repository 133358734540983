import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Spin, message, Button} from 'antd';
import { getItem, ItemDetails as ItemDetailsType } from '../services/itemService';
import ItemDetails from '../components/itemDetails';

const ItemPage: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [item, setItem] = useState<ItemDetailsType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const loadItem = async () => {
        if (!id) {
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const data = await getItem(id);
            setItem(data);
        } catch (error) {
            message.error('Не удалось загрузить информацию об устройстве.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItem();
    }, [id]);

    if (loading) {
        return <Spin tip="Загрузка..." />;
    }

    if (!item) {
        return (
            <div>
                <Button onClick={() => navigate('/items')} style={{ marginBottom: '16px' }}>
                    Назад
                </Button>
                <div>Устройство не найден</div>
            </div>
        );    }

    return (
        <div>
            <Button onClick={() => navigate('/items')} style={{ marginBottom: '16px' }}>
                Назад
            </Button>
            <ItemDetails item={item} onRefresh={loadItem} />
        </div>
    );
};

export default ItemPage;
