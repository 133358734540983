import { Client, IMessage } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {Alert} from "./alerts";

interface User {
    role: string;
    organizationId?: string;
}

class WebSocketService {
    private user: User;
    private stompClient: Client | null = null;

    private onAlertMessage?: (alert: Alert) => void;

    constructor(user: User) {
        this.user = user;
    }

    public setOnAlertMessage(callback: (alert: Alert) => void) {
        this.onAlertMessage = callback;
    }

    public connect(): void {
        const token = localStorage.getItem('accessToken');
        const { organizationId, role } = this.user;

        const backendUrl = process.env.REACT_APP_BACKEND_URL || '';
        const socketUrl = `${backendUrl}/ws?token=${token}`;
        const socket = new SockJS(socketUrl);

        this.stompClient = new Client({
            webSocketFactory: () => socket as unknown as WebSocket,
            reconnectDelay: 5000,
            debug: (str: string) => {
                console.log(str);
            },
        });

        this.stompClient.onConnect = () => {
            console.log('Подключение к WebSocket установлено.');

            if (!this.stompClient) return;

            if (role === 'ROLE_ADMIN') {
                this.stompClient.subscribe('/topic/admin/alerts', (message: IMessage) => {
                    console.log('Получено сообщение для ADMIN:', message.body);
                    this.handleIncomingMessage(message.body);
                });
            } else if (role === 'ROLE_CUSTOMER' && organizationId) {
                this.stompClient.subscribe(`/topic/org/${organizationId}/alerts`, (message: IMessage) => {
                    console.log('Получено сообщение для CUSTOMER:', message.body);
                    this.handleIncomingMessage(message.body);
                });
            }
        };

        this.stompClient.onStompError = (frame) => {
            console.error('STOMP ошибка: ', frame.headers['message']);
            console.error('Дополнительные данные: ', frame.body);
        };

        this.stompClient.activate();
    }

    public disconnect(): void {
        if (this.stompClient && this.stompClient.active) {
            this.stompClient.deactivate();
            console.log('Отключение от WebSocket.');
        }
    }

    private handleIncomingMessage(rawBody: string) {
        try {
            const alert: Alert = JSON.parse(rawBody);
            if (this.onAlertMessage) {
                this.onAlertMessage(alert);
            }
        } catch (err) {
            console.error('Ошибка при парсинге входящего сообщения', err);
        }
    }
}

export default WebSocketService;
