import React, { useState } from 'react';
import { Typography, Button, List, Modal, Form, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
    addMapping,
    deleteMapping,
    createAttribute,
    setAttribute,
    deleteAttribute,
    ItemDetails as ItemDetailsType,
    MappingRequestDto,
    AttributeRequestDto,
} from '../services/itemService';

const { Title, Text } = Typography;

interface ItemDetailsProps {
    item: ItemDetailsType;
    onRefresh: () => void;
}

const ItemDetails: React.FC<ItemDetailsProps> = ({ item, onRefresh }) => {
    const [mappingModalVisible, setMappingModalVisible] = useState<boolean>(false);
    const [attributeModalVisible, setAttributeModalVisible] = useState<boolean>(false);
    const [currentAttributeKey, setCurrentAttributeKey] = useState<string>('');
    const [mappingForm] = Form.useForm();
    const [attributeForm] = Form.useForm();

    const handleAddMapping = async () => {
        try {
            const values = await mappingForm.validateFields();
            const mappingData: MappingRequestDto = {
                itemId: item.uuid,
                mapping: values.mapping,
            };
            await addMapping(mappingData);
            message.success('Маппинг успешно добавлен!');
            setMappingModalVisible(false);
            mappingForm.resetFields();
            onRefresh();
        } catch (error: any) {
            message.error(error.message || 'Не удалось добавить маппинг.');
        }
    };

    const handleDeleteMapping = async (mapping: string) => {
        try {
            const mappingData: MappingRequestDto = {
                itemId: item.uuid,
                mapping,
            };
            await deleteMapping(mappingData);
            message.success('Маппинг успешно удален!');
            onRefresh();
        } catch (error: any) {
            message.error(error.message || 'Не удалось удалить маппинг.');
        }
    };

    const handleAddAttribute = async (values: any) => {
        try {
            const attributeData: AttributeRequestDto = {
                itemId: item.uuid,
                name: values.key,
                value: values.value,
            };
            await createAttribute(attributeData);
            message.success('Атрибут успешно добавлен!');
            setAttributeModalVisible(false);
            attributeForm.resetFields();
            onRefresh();
        } catch (error: any) {
            message.error(error.message || 'Не удалось добавить атрибут.');
        }
    };

    const handleUpdateAttribute = async (values: any) => {
        try {
            const attributeData: AttributeRequestDto = {
                itemId: item.uuid,
                name: currentAttributeKey,
                value: values.value,
            };
            await setAttribute(attributeData);
            message.success('Атрибут успешно обновлен!');
            setCurrentAttributeKey('');
            setAttributeModalVisible(false);
            attributeForm.resetFields();
            onRefresh();
        } catch (error: any) {
            message.error(error.message || 'Не удалось обновить атрибут.');
        }
    };

    const handleDeleteAttribute = async (key: string) => {
        try {
            const attributeData: AttributeRequestDto = {
                itemId: item.uuid,
                name: key,
            };
            await deleteAttribute(attributeData);
            message.success('Атрибут успешно удален!');
            onRefresh();
        } catch (error: any) {
            message.error(error.message || 'Не удалось удалить атрибут.');
        }
    };

    return (
        <div>
            <Title level={2}>{item.name}</Title>
            <Text strong>Серийный номер:</Text> {item.serialNumber}
            <br />
            <Text strong>Организация:</Text> {item.organizationName}
            <br />
            <Text strong>Создано:</Text> {new Date(item.createdAt).toLocaleString()}
            <br />
            <Text strong>Обновлено:</Text> {new Date(item.updatedAt).toLocaleString()}
            <br /><br />

            <Title level={4}>Маппинги</Title>
            <List
                bordered
                dataSource={item.mappings}
                renderItem={mapping => (
                    <List.Item
                        actions={[
                            <Button type="link" danger onClick={() => handleDeleteMapping(mapping)}>
                                Удалить
                            </Button>
                        ]}
                        key={mapping}
                    >
                        {mapping}
                    </List.Item>
                )}
            />
            <Button
                type="dashed"
                icon={<PlusOutlined />}
                style={{ marginTop: '10px' }}
                onClick={() => setMappingModalVisible(true)}
            >
                Добавить Маппинг
            </Button>

            <Title level={4} style={{ marginTop: '20px' }}>Атрибуты</Title>
            <List
                bordered
                dataSource={Object.entries(item.attributes)}
                renderItem={([key, value]) => (
                    <List.Item
                        actions={[
                            <Button
                                type="link"
                                onClick={() => {
                                    setCurrentAttributeKey(key);
                                    attributeForm.setFieldsValue({ value });
                                    setAttributeModalVisible(true);
                                }}
                            >
                                Изменить
                            </Button>,
                            <Button type="link" danger onClick={() => handleDeleteAttribute(key)}>
                                Удалить
                            </Button>
                        ]}
                        key={key}
                    >
                        <Text strong>{key}:</Text> {value}
                    </List.Item>
                )}
            />
            <Button
                type="dashed"
                icon={<PlusOutlined />}
                style={{ marginTop: '10px' }}
                onClick={() => {
                    setCurrentAttributeKey('');
                    attributeForm.resetFields();
                    setAttributeModalVisible(true);
                }}
            >
                Добавить Атрибут
            </Button>

            {/* Модальное окно для добавления маппинга */}
            <Modal
                title="Добавить Маппинг"
                visible={mappingModalVisible}
                onOk={handleAddMapping}
                onCancel={() => setMappingModalVisible(false)}
                okText="Добавить"
                cancelText="Отмена"
            >
                <Form form={mappingForm} layout="vertical">
                    <Form.Item
                        label="Маппинг"
                        name="mapping"
                        rules={[{ required: true, message: 'Пожалуйста, введите маппинг' }]}
                    >
                        <Input placeholder="Маппинг" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={currentAttributeKey ? `Изменить Атрибут: ${currentAttributeKey}` : 'Добавить Атрибут'}
                visible={attributeModalVisible}
                onOk={() => {
                    attributeForm.submit();
                }}
                onCancel={() => {
                    setAttributeModalVisible(false);
                    setCurrentAttributeKey('');
                    attributeForm.resetFields();
                }}
                okText={currentAttributeKey ? 'Изменить' : 'Добавить'}
                cancelText="Отмена"
            >
                <Form
                    form={attributeForm}
                    layout="vertical"
                    onFinish={currentAttributeKey ? handleUpdateAttribute : handleAddAttribute}
                >
                    {!currentAttributeKey && (
                        <Form.Item
                            label="Ключ"
                            name="key"
                            rules={[{ required: true, message: 'Пожалуйста, введите ключ атрибута' }]}
                        >
                            <Input placeholder="Ключ" />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Значение"
                        name="value"
                        rules={[{ required: true, message: 'Пожалуйста, введите значение атрибута' }]}
                    >
                        <Input placeholder="Значение" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ItemDetails;
