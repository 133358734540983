import React, {useEffect, useState, FC} from 'react';
import {Table, Spin} from 'antd';
import {TablePaginationConfig} from "antd/lib/table";
import { Case } from '../services/caseService';

interface PaginationProps {
    caseCurrentPage: number;
    casePageSize: number;
    caseTotal: number;
}

interface CasesDashboardProps {
    cases: Case[];
    loading: boolean;
    pagination: PaginationProps;
    onChange: (pagination: TablePaginationConfig) => void;
}

const CasesDashboard: FC<CasesDashboardProps> = ({ cases, loading, pagination, onChange }) => {
    const [caseList, setCaseList] = useState<Case[]>(cases);

    useEffect(() => {
        setCaseList(cases);
    }, [cases]);

    const columns = [
        {
            title: 'Номер',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Тема',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Устройство',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Время создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => new Date(date).toLocaleString(),
        },
    ];

    return loading ? (
        <Spin />
    ) : (
        <Table
            columns={columns}
            dataSource={caseList}
            rowKey="id"
            pagination={{
                current: pagination.caseCurrentPage,
                pageSize: pagination.casePageSize,
                total: pagination.caseTotal,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: (total: number, range: [number, number]) =>
                    `${range[0]}-${range[1]} из ${total} записей`,
            }}
            onChange={onChange}
        />
    );
};

export default CasesDashboard;
