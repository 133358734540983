import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/public`,
    headers: {
        'Content-Type': 'application/json',
    },
});

const handleTokenError = () => {
    console.log("handleTokenError")
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
};

const refreshAccessToken = async (): Promise<string> => {
    console.log("refreshAccessToken")
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        console.log("!refreshToken")

        handleTokenError();
        throw new Error('Refresh token is missing.');
    }

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/public/users/refresh`, {
        token: refreshToken,
    });

    if (response.status !== 200) {
        handleTokenError();
        throw new Error('Failed to refresh access token.');
    }

    const newAccessToken = response.data.accessToken;
    localStorage.setItem('accessToken', newAccessToken);
    return newAccessToken;
};

api.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        console.log(accessToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                console.log("Invalid access token");
                const newAccessToken = await refreshAccessToken();
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.log("Cant refresh access token");
                handleTokenError();
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default api;
