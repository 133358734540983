import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import { CustomerRequestDto } from '../services/customerService';
import { updateUser } from '../services/customerService';
import {User} from "../contexts/userContext";

const { Option } = Select;

interface EditCustomerModalProps {
    visible: boolean;
    onCancel: () => void;
    onSuccess: () => void;
    user: User | null;
    organizations: { id: string; name: string }[];
}

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({ visible, onCancel, onSuccess, user, organizations }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                name: user.name,
                email: user.email,
                department: user.department,
                mobile: user.mobile,
                organizationId: user.organizationId,
                password: '',
            });
        } else {
            form.resetFields();
        }
    }, [user, form]);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const updatedData: CustomerRequestDto = {
                name: values.name,
                email: values.email,
                department: values.department,
                mobile: values.mobile,
                organizationId: values.organizationId,
            };

            if (values.password) {
                updatedData.password = values.password;
            }

            await updateUser(updatedData);
            message.success('Пользователь успешно обновлен.');
            form.resetFields();
            onSuccess();
        } catch (error: any) {
            if (error.name === 'ValidationError') {
                message.error(error.message);
            } else {
                message.error('Не удалось обновить пользователя.');
            }
        }
    };

    return (
        <Modal
            visible={visible}
            title="Изменить пользователя"
            okText="Сохранить"
            cancelText="Отмена"
            onCancel={onCancel}
            onOk={handleOk}
            destroyOnClose
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="email"
                    label="Email"

                    rules={[{type: 'email', message: 'Пожалуйста, введите корректный email!' }]}
                >
                    <Input disabled /> {/* Email обычно не изменяется */}
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ min: 6, message: 'Пароль должен содержать минимум 6 символов.' }]}
                    tooltip="Оставьте поле пустым, если не хотите изменять пароль."
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Пожалуйста, введите имя!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="department"
                    label="Department"
                    rules={[{ required: true, message: 'Пожалуйста, введите отдел!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="mobile"
                    label="Mobile"
                    rules={[{ required: true, message: 'Пожалуйста, введите номер мобильного!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="organizationId"
                    label="Organization"
                    rules={[{ required: true, message: 'Пожалуйста, выберите организацию!' }]}
                >
                    <Select placeholder="Select organization" style={{ width: '100%' }}>
                        {organizations.map((org) => (
                            <Option key={org.id} value={org.id}>
                                {org.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditCustomerModal;
