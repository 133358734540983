import api from './api';
import { User } from '../contexts/userContext';

export interface CustomerRequestDto {
    password?: string;
    name?: string;
    email: string;
    department?: string;
    mobile?: string;
    organizationId?: string;
}

interface DeleteCustomerRequestDto {
    email: string;
}

interface CreateUserResponse {
    user: User;
}

interface UpdateUserResponse {
    user: User;
}

interface ChangePasswordDto {
    oldPassword: string;
    newPassword: string;
}

export const createUser = async (data: CustomerRequestDto): Promise<CreateUserResponse> => {
    const response = await api.post('/users/create', data);

    if (response.status !== 200) {
        throw new Error('User creation failed');
    }

    return response.data;
};

export const updateUser = async (data: CustomerRequestDto): Promise<UpdateUserResponse> => {
    const response = await api.post('/users/update', data);
    if (response.status !== 200) {
        throw new Error('User update failed');
    }
    return response.data;
};

export const deleteUser = async (data: DeleteCustomerRequestDto): Promise<void> => {
    const response = await api.post(`/users/delete`, data);

    if (response.status !== 200) {
        throw new Error('User deletion failed');
    }
};

export const fetchCustomers = async (): Promise<User[]> => {
    const response = await api.get('/users/customers');

    if (response.status !== 200) {
        throw new Error('Failed to fetch customers');
    }

    return response.data;
};

export const changePassword = async (data: ChangePasswordDto): Promise<void> => {
    try {
        const response = await api.post('/users/change_password', data);
        if (response.status !== 200) {
            throw new Error('Невозможно изменить пароль');
        }
    } catch (error: any) {
        if (error.response?.status === 404) {
            throw new Error('Пользователь не найден');
        } else if (error.response?.status === 403) {
            throw new Error('Неверный пароль');
        } else {
            throw new Error(error.response?.data?.message || 'Невозможно изменить пароль');
        }
    }
};