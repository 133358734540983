import api from './api';
import { AxiosResponse } from 'axios';

export interface Item {
    uuid: string;
    name: string;
    serialNumber?: string;
    organizationId: string;
}

export interface ItemDetails {
    uuid: string;
    name: string;
    serialNumber?: string;
    organizationId: string;
    organizationName: string;
    mappings: string[];
    attributes: { [key: string]: string };
    createdAt: string;
    updatedAt: string;
}

export interface AttributeRequestDto {
    itemId: string;
    name: string;
    value?: string;
}

export interface MappingRequestDto {
    itemId: string;
    mapping: string;
}

export interface GetItemsParams {
    name?: string;
    organizationId?: string;
    limit?: number;
    offset?: number;
}

export const getAllItems = async (params: GetItemsParams = {}): Promise<ItemDetails[]> => {
    const response: AxiosResponse<ItemDetails[]> = await api.get('/items', { params });

    if (response.status !== 200) {
        throw new Error('Не удалось получить список устройств');
    }

    return response.data;
};

export const getItem = async (uuid: string): Promise<ItemDetails> => {
    const response: AxiosResponse<ItemDetails> = await api.get(`/items/${uuid}`);

    if (response.status !== 200) {
        throw new Error('Не удалось получить устройство');
    }

    return response.data;
};

export const createItem = async (item: Item): Promise<Item> => {
    const response: AxiosResponse<Item> = await api.post('/items', item);

    if (response.status !== 200 && response.status !== 201) {
        throw new Error('Создание устройства не удалось');
    }

    return response.data;
};

export const updateItem = async (item: Item): Promise<Item> => {
    const response: AxiosResponse<Item> = await api.put('/items', item);

    if (response.status !== 200) {
        throw new Error('Обновление устройства не удалось');
    }

    return response.data;
};

export const deleteItem = async (item: Item): Promise<void> => {
    const response: AxiosResponse = await api.delete('/items', { data: item });

    if (response.status !== 200 && response.status !== 204) {
        throw new Error('Удаление устройства не удалось');
    }
};

export const addMapping = async (mapping: MappingRequestDto): Promise<void> => {
    const response: AxiosResponse = await api.post('/items/mappings', mapping);

    if (response.status !== 200) {
        throw new Error('Добавление маппинга не удалось');
    }
};

export const deleteMapping = async (mapping: MappingRequestDto): Promise<void> => {
    const response: AxiosResponse = await api.delete('/items/mappings', { data: mapping });

    if (response.status !== 200 && response.status !== 204) {
        throw new Error('Удаление маппинга не удалось');
    }
};

export const createAttribute = async (attribute: AttributeRequestDto): Promise<void> => {
    const response: AxiosResponse = await api.post('/items/attributes', attribute);

    if (response.status !== 200) {
        throw new Error('Создание атрибута не удалось');
    }
};

export const setAttribute = async (attribute: AttributeRequestDto): Promise<void> => {
    const response: AxiosResponse = await api.put('/items/attributes', attribute);

    if (response.status !== 200) {
        throw new Error('Обновление атрибута не удалось');
    }
};

export const deleteAttribute = async (attribute: AttributeRequestDto): Promise<void> => {
    const response: AxiosResponse = await api.delete('/items/attributes', { data: attribute });

    if (response.status !== 200 && response.status !== 204) {
        throw new Error('Удаление атрибута не удалось');
    }
};