import React, {useContext, useEffect, useRef, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Spin, message, Button, Form, Select, Input, Checkbox, Typography} from 'antd';
import { getItem, ItemDetails as ItemDetailsType } from '../services/itemService';
import ItemDetails from '../components/itemDetails';
import {UserContext} from "../contexts/userContext";
import WebSocketService from "../services/websocketService";
import {Alert, AlertFilterRequestDto, fetchAlerts, FetchAlertsResponse} from "../services/alerts";
import {TablePaginationConfig} from "antd/lib/table";
import AlertsDashboard from "../components/alertsDashboard";
const { Title } = Typography;
const { Option } = Select;

const ItemPage: React.FC = () => {
    const user = useContext(UserContext);
    const wsServiceRef = useRef<WebSocketService | null>(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const [item, setItem] = useState<ItemDetailsType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [form] = Form.useForm();

    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [loadingAlerts, setLoadingAlerts] = useState<boolean>(false);

    const [filters, setFilters] = useState<AlertFilterRequestDto>({});

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    const loadItem = async () => {
        if (!id) {
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const data = await getItem(id);
            setItem(data);
        } catch (error) {
            message.error('Не удалось загрузить информацию об устройстве.');
        } finally {
            setLoading(false);
        }
    };

    const loadAlerts = async () => {
        setLoadingAlerts(true);
        try {
            filters.itemId = id;
            const limit = pageSize;
            const offset = (currentPage - 1) * pageSize;
            const filtersWithPagination: AlertFilterRequestDto = { ...filters, limit, offset };
            const response: FetchAlertsResponse = await fetchAlerts(filtersWithPagination);

            setAlerts(response.alerts);
            setTotal(response.total);
        } catch (error) {
            message.error('Не удалось загрузить уведомления.');
        } finally {
            setLoadingAlerts(false);
        }
    };

    useEffect(() => {
        loadItem();
    }, [id]);

    useEffect(() => {
        loadAlerts();
    }, [filters, currentPage, pageSize]);

    useEffect(() => {
        if (user) {
            const service = new WebSocketService(user);
            wsServiceRef.current = service;

            service.connect();

            return () => {
                service.disconnect();
            };
        }
    }, [user]);

    useEffect(() => {
        form.setFieldsValue(filters);
    }, [filters, form]);

    useEffect(() => {
        if (wsServiceRef.current) {
            wsServiceRef.current.setOnAlertMessage((newAlert: Alert) => {
                if (checkAlertMatchesFilters(newAlert, filters)) {
                    setAlerts((prevAlerts) => {
                        const index = prevAlerts.findIndex((a) => a.id === newAlert.id);
                        if (index === -1) {
                            setTotal((prevTotal) => prevTotal + 1);
                            return [newAlert, ...prevAlerts];
                        } else {
                            const updatedAlerts = [...prevAlerts];
                            updatedAlerts[index] = newAlert;
                            return updatedAlerts;
                        }
                    });
                }
            });
        }
    }, [filters]);

    const checkAlertMatchesFilters = (alert: Alert, filters: AlertFilterRequestDto): boolean => {
        if (alert.itemId !== id) {
            return false;
        }
        if (filters.open !== undefined && filters.open !== null) {
            if (alert.open !== filters.open) {
                return false;
            }
        }
        if (filters.name && filters.name.trim() !== '') {
            if (!alert.name.toLowerCase().includes(filters.name.toLowerCase())) {
                return false;
            }
        }
        if (filters.resource && filters.resource.trim() !== '') {
            if (!alert.resource.toLowerCase().includes(filters.resource.toLowerCase())) {
                return false;
            }
        }
        if (filters.severity && filters.severity.length > 0) {
            if (!filters.severity.includes(alert.severity)) {
                return false;
            }
        }
        if (filters.expired) {
            if (!alert.expired) {
                return false;
            }
        }
        if (filters.organizationId) {
            if (alert.organizationId !== filters.organizationId) {
                return false;
            }
        }

        return true;
    };

    if (loading) {
        return <Spin tip="Загрузка..." />;
    }

    const handleFormSubmit = (values: AlertFilterRequestDto) => {
        setFilters(values);
        setCurrentPage(1);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    if (!item) {
        return (
            <div>
                <Button onClick={() => navigate('/items')} style={{ marginBottom: '16px' }}>
                    Назад
                </Button>
                <div>Устройство не найден</div>
            </div>
        );    }

    return (
        <div>
            <Button onClick={() => navigate('/items')} style={{ marginBottom: '16px' }}>
                Назад
            </Button>
            <ItemDetails item={item} onRefresh={loadItem} />
            <Title level={3} style={{ marginTop: '20px' }}>Уведомления</Title>
            <Form form={form} layout="inline" onFinish={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <Form.Item name="name" label="Название">
                    <Input placeholder="Введите название" />
                </Form.Item>
                <Form.Item name="open" label="Статус">
                    <Select allowClear placeholder="Выберите статус">
                        <Option value="">Все</Option>
                        <Option value={true}>Открытые</Option>
                        <Option value={false}>Закрытые</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="severity" label="Критичность">
                    <Select mode="multiple" allowClear placeholder="Выберите критичность">
                        <Option value="OK">OK</Option>
                        <Option value="WARN">WARN</Option>
                        <Option value="ERROR">ERROR</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="expired" valuePropName="checked">
                    <Checkbox>Истекло</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Применить
                    </Button>
                </Form.Item>
            </Form>
            <AlertsDashboard
                alerts={alerts}
                loading={loadingAlerts}
                pagination={{ currentPage, pageSize, total }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default ItemPage;
