import React, { useState } from 'react';
import {Form, Input, Button, message, Select} from 'antd';
import { createUser } from '../services/customerService';

const { Option } = Select;

interface CustomerFormProps {
    organizations: { uuid: string; name: string }[];
    refreshCustomers: () => void;
}

const CreateCustomerForm: React.FC<CustomerFormProps> = ({ organizations, refreshCustomers }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values: any) => {
        if (!form) {
            message.warning('Please select all fields.');
            return;
        }
        setLoading(true);
        try {
            const { password, name, email, department, mobile, organizationId } = values;
            await createUser({
                password,
                name,
                email,
                department,
                mobile,
                organizationId,
            });
            form.resetFields();
            message.success('Customer created successfully!');
            refreshCustomers();
        } catch (error: any) {
            console.log(error.status);
            if (error.status === 409)
                message.error('Пользователь с таким email уже существует')
            else
                message.error('Ошибка при создании пользователя');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form form={form} onFinish={onSubmit} layout="vertical">
            <Form.Item
                name="email"
                label="Почта"
                rules={[{ required: true, type: 'email', message: 'Введите почту!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Пароль"
                rules={[{ required: true, message: 'Введите пароль!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="name"
                label="Имя"
                rules={[{ required: true, message: 'Введите имя!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="department"
                label="Отдел"
                rules={[{ required: true, message: 'Введите отдел!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="mobile"
                label="Телефон"
                rules={[{ required: true, message: 'Введите номер телефона!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="organizationId"
                label="Организация"
                rules={[{ required: true, message: 'Выберите организацию!' }]}
            >
                <Select placeholder="Select organization" style={{ width: 200 }}>
                    {organizations.map((org) => (
                        <Option key={org.uuid} value={org.uuid}>
                            {org.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Создать
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateCustomerForm;