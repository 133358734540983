import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png';
import { User } from '../contexts/userContext';
import { login } from '../services/authService';

const { Title } = Typography;

interface LoginProps {
    onLogin: (user: User) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (values: { login: string; password: string }) => {
        setLoading(true);
        try {
            const data = await login(values.login, values.password);

            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('user', JSON.stringify(data.user));

            onLogin(data.user);

            navigate('/dashboard');
            message.success('Login successful');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    console.log(process.env.REACT_APP_BACKEND_URL);

    return (
        <div
            style={{
                maxWidth: 300,
                margin: '100px auto',
                padding: '20px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifySelf: 'center' }}>
                <img src={logo} alt="Logo" style={{ height: '40px', marginBottom: '25px' }} />
            </div>
            <Title level={2} style={{ textAlign: 'center' }}>
                Login
            </Title>
            <Form
                name="login_form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="login"
                    rules={[{ required: true, message: 'Please input your login!' }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Login" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ width: '100%' }}
                    >
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
