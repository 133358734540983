import React from 'react';
import {List, Button, Spin, Typography, Space, Popconfirm} from 'antd';
import {DeleteOutlined, EditOutlined, ReloadOutlined} from '@ant-design/icons';
import { License } from '../services/licenses';

const { Text } = Typography;

interface LicenseListProps {
    licenses: License[];
    loading: boolean;
    onReload: () => void;
    handleUpdate: (license: License) => void;
    handleDelete: (licenseId: string) => void;
}

const LicenseList: React.FC<LicenseListProps> = ({ licenses, loading, onReload, handleUpdate, handleDelete }) => {
    return (
        <>
            <Button
                icon={<ReloadOutlined />}
                onClick={onReload}
                loading={loading}
                style={{ marginBottom: '10px' }}
            >
                Обновить
            </Button>
            {loading ? (
                <Spin />
            ) : (
                <List
                    bordered
                    dataSource={licenses}
                    renderItem={(license) => (
                        <List.Item key={license.uuid}>
                            <div>
                                <strong>{license.uuid}</strong>
                                <br />
                                <Text type="secondary">
                                    Дата окончания: {new Date(license.expirationDate).toLocaleString()}
                                </Text>
                                <br />
                                <Text type="secondary">Организация: {license.organizationName}</Text>
                            </div>
                            <Space>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => handleUpdate(license)}
                                >
                                    Изменить
                                </Button>
                                <Popconfirm
                                    title="Вы уверены, что хотите удалить лицензию?"
                                    onConfirm={() => handleDelete(license.uuid)}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button
                                        danger
                                        icon={<DeleteOutlined/>}
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
};

export default LicenseList;
