import React, { useState } from 'react';
import { Form, Select, DatePicker, Button, message } from 'antd';
import moment from 'moment';
import { handleAddLicense } from '../services/licenses';

const { Option } = Select;

interface LicenseFormProps {
    organizations: { uuid: string; name: string }[];
    refreshLicenses: () => void;
}

const LicenseForm: React.FC<LicenseFormProps> = ({ organizations, refreshLicenses }) => {
    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        console.log(selectedOrgId)
        console.log(expirationDate)
        if (!selectedOrgId || !expirationDate) {
            message.warning('Please select an organization and expiration date.');
            return;
        }

        setLoading(true);
        try {
            await handleAddLicense(selectedOrgId, expirationDate.toISOString());
            setSelectedOrgId('');
            setExpirationDate(null);
            message.success('License added successfully!');
            refreshLicenses();
        } catch (error) {
            message.error('Failed to add license.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onFinish={onSubmit} layout="inline">
            <Form.Item>
                <Select
                    placeholder="Выберите организацию"
                    style={{ width: 200 }}
                    value={selectedOrgId}
                    onChange={(value) => setSelectedOrgId(value)}
                >
                    {organizations.map((org) => (
                        <Option key={org.uuid} value={org.uuid}>{org.name}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <DatePicker
                    placeholder="Дата окончания"
                    style={{ width: 200 }}
                    value={expirationDate}
                    onChange={(date) => setExpirationDate(date)}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Создать лицензию
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LicenseForm;
