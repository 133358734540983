import api from './api';
import { User } from '../contexts/userContext';

interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

export const login = async (login: string, password: string): Promise<LoginResponse> => {
    const response = await api.post('/users/auth', { login, password });

    if (response.status !== 200) {
        throw new Error('Authentication failed');
    }

    return response.data;
};