import React, {useState, useEffect, useContext, useRef} from 'react';
import {Typography, message, Form, Select, Checkbox, Input, Button, Spin, Col, Row} from 'antd';
import {fetchAlerts, Alert, AlertFilterRequestDto, FetchAlertsResponse} from '../services/alerts';
import AlertsDashboard from "../components/alertsDashboard";
import {UserContext} from "../contexts/userContext";
import {fetchOrganizations, Organization} from "../services/organizations";
import { TablePaginationConfig } from 'antd/lib/table';
import WebSocketService from '../services/websocketService';
import {Case, CaseFilterRequestDto, fetchCases, FetchCasesResponse} from "../services/caseService";
import CasesDashboard from "../components/casesDashboard";

const { Title } = Typography;
const { Option } = Select;

const Dashboard: React.FC = () => {
    const user = useContext(UserContext);
    const wsServiceRef = useRef<WebSocketService | null>(null);

    const [form] = Form.useForm();
    const [caseForm] = Form.useForm();

    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [cases, setCases] = useState<Case[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingAlerts, setLoadingAlerts] = useState<boolean>(false);
    const [loadingCases, setLoadingCases] = useState<boolean>(false);
    const [loadingOrganizations, setLoadingOrganizations] = useState<boolean>(false);

    const [filters, setFilters] = useState<AlertFilterRequestDto>({});
    const [caseFilters, setCaseFilters] = useState<CaseFilterRequestDto>({});

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    const [caseCurrentPage, setCaseCurrentPage] = useState<number>(1);
    const [casePageSize, setCasePageSize] = useState<number>(10);
    const [caseTotal, setCaseTotal] = useState<number>(0);

    const loadAlerts = async () => {
        setLoadingAlerts(true);
        try {
            const limit = pageSize;
            const offset = (currentPage - 1) * pageSize;
            const filtersWithPagination: AlertFilterRequestDto = { ...filters, limit, offset };
            const response: FetchAlertsResponse = await fetchAlerts(filtersWithPagination);

            setAlerts(response.alerts);
            setTotal(response.total);
        } catch (error) {
            message.error('Не удалось загрузить уведомления.');
        } finally {
            setLoadingAlerts(false);
        }
    };

    const loadCases = async () => {
        setLoadingCases(true);
        try {
            const limit = casePageSize;
            const offset = (caseCurrentPage - 1) * casePageSize;
            const filtersWithPagination: CaseFilterRequestDto = { ...caseFilters, limit, offset };
            const response: FetchCasesResponse = await fetchCases(filtersWithPagination);

            setCases(response.cases);
            setCaseTotal(response.total);
        } catch (error) {
            message.error('Не удалось загрузить обращения.');
        } finally {
            setLoadingCases(false);
        }
    };

    const loadOrganizations = async () => {
        setLoadingOrganizations(true);
        try {
            const data = await fetchOrganizations();
            setOrganizations(data);
        } catch (error) {
            message.error('Не удалось загрузить организации.');
        } finally {
            setLoadingOrganizations(false);
        }
    };

    useEffect(() => {
        loadAlerts();
    }, [filters, currentPage, pageSize]);

    useEffect(() => {
        loadCases();
    }, [caseFilters, caseCurrentPage, casePageSize]);

    useEffect(() => {
        if (user?.role === 'ROLE_ADMIN') {
            loadOrganizations();
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            const service = new WebSocketService(user);
            wsServiceRef.current = service;

            service.connect();

            return () => {
                service.disconnect();
            };
        }
    }, [user]);

    useEffect(() => {
        form.setFieldsValue(filters);
    }, [filters, form]);

    useEffect(() => {
        caseForm.setFieldsValue(caseFilters);
    }, [caseFilters, caseForm]);

    useEffect(() => {
        if (wsServiceRef.current) {
            wsServiceRef.current.setOnAlertMessage((newAlert: Alert) => {
                if (checkAlertMatchesFilters(newAlert, filters)) {
                    setAlerts((prevAlerts) => {
                        const index = prevAlerts.findIndex((a) => a.id === newAlert.id);
                        if (index === -1) {
                            setTotal((prevTotal) => prevTotal + 1);
                            return [newAlert, ...prevAlerts];
                        } else {
                            const updatedAlerts = [...prevAlerts];
                            updatedAlerts[index] = newAlert;
                            return updatedAlerts;
                        }
                    });
                }
            });
        }
    }, [filters]);

    const checkAlertMatchesFilters = (alert: Alert, filters: AlertFilterRequestDto): boolean => {
        if (filters.open !== undefined && filters.open !== null) {
            if (alert.open !== filters.open) {
                return false;
            }
        }
        if (filters.name && filters.name.trim() !== '') {
            if (!alert.name.toLowerCase().includes(filters.name.toLowerCase())) {
                return false;
            }
        }
        if (filters.resource && filters.resource.trim() !== '') {
            if (!alert.resource.toLowerCase().includes(filters.resource.toLowerCase())) {
                return false;
            }
        }
        if (filters.severity && filters.severity.length > 0) {
            if (!filters.severity.includes(alert.severity)) {
                return false;
            }
        }
        if (filters.expired) {
            if (!alert.expired) {
                return false;
            }
        }
        if (filters.organizationId) {
            if (alert.organizationId !== filters.organizationId) {
                return false;
            }
        }

        return true;
    };

    const handleFormSubmit = (values: AlertFilterRequestDto) => {
        setFilters(values);
        setCurrentPage(1);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    const handleCaseFormSubmit = (values: CaseFilterRequestDto) => {
        setCaseFilters(values);
        setCaseCurrentPage(1);
    };

    const handleCaseTableChange = (pagination: TablePaginationConfig) => {
        setCaseCurrentPage(pagination.current || 1);
        setCasePageSize(pagination.pageSize || 10);
    };

    return (
        <>
            <Title level={3}>Уведомления</Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFormSubmit}
            >
                <Row gutter={[15, 0]}>
                    {user?.role === 'ROLE_ADMIN' && (
                        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            <Form.Item
                                label="Организация"
                                name="organizationId"
                                rules={[{ required: false, message: 'Пожалуйста, выберите организацию' }]}
                            >
                                {loadingOrganizations ? (
                                    <Spin />
                                ) : (
                                    <Select placeholder="Выберите организацию" allowClear>
                                        <Option value="">Все</Option>
                                        {organizations.map(org => (
                                            <Option key={org.uuid} value={org.uuid}>
                                                {org.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="name" label="Название">
                            <Input placeholder="Введите название" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="resource" label="Ресурс">
                            <Input placeholder="Введите ресурс" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="open" label="Статус">
                            <Select allowClear placeholder="Выберите статус">
                                <Option value="">Все</Option>
                                <Option value={true}>Открытые</Option>
                                <Option value={false}>Закрытые</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="severity" label="Критичность">
                            <Select mode="multiple" allowClear placeholder="Выберите критичность">
                                <Option value="OK">OK</Option>
                                <Option value="WARN">WARN</Option>
                                <Option value="ERROR">ERROR</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={9} sm={6} md={4} lg={3} xl={2} xxl={1.5}>
                        <Form.Item name="expired" valuePropName="checked">
                            <Checkbox>Истекло</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Применить
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <AlertsDashboard
                alerts={alerts}
                loading={loadingAlerts}
                pagination={{ currentPage, pageSize, total }}
                onChange={handleTableChange}
            />
            <Title level={3}>Обращения</Title>
            <Form
                form={caseForm}
                layout="vertical"
                onFinish={handleCaseFormSubmit}
            >
                <Row gutter={[15, 0]}>
                    {user?.role === 'ROLE_ADMIN' && (
                        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            <Form.Item
                                label="Организация"
                                name="organizationId"
                                rules={[{ required: false, message: 'Пожалуйста, выберите организацию' }]}
                            >
                                {loadingOrganizations ? (
                                    <Spin />
                                ) : (
                                    <Select placeholder="Выберите организацию" allowClear>
                                        <Option value="">Все</Option>
                                        {organizations.map(org => (
                                            <Option key={org.uuid} value={org.uuid}>
                                                {org.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="title" label="Тема">
                            <Input placeholder="Введите тему" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="number" label="Номер">
                            <Input placeholder="Введите номер" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="status" label="Статус">
                            <Select mode="multiple" allowClear placeholder="Выберите статус">
                                <Option value="NEW">Новое</Option>
                                <Option value="REOPENED">Переоткрыто</Option>
                                <Option value="IN_PROGRESS">В процессе</Option>
                                <Option value="WAITING_FOR_REACTION">Ожидает реакции</Option>
                                <Option value="SOLVED">Решено</Option>
                                <Option value="CLOSED">Закрыто</Option>
                                <Option value="CANCELED">Отменено</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Form.Item name="itemName" label="Имя устройства">
                            <Input placeholder="Введите имя устройства" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Применить
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CasesDashboard
                cases={cases}
                loading={loadingCases}
                pagination={{ caseCurrentPage, casePageSize, caseTotal }}
                onChange={handleCaseTableChange}
            />
        </>
    );
};

export default Dashboard;
