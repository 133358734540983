import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { changePassword } from '../services/customerService';
import { useNavigate } from 'react-router-dom';

const ChangePasswordPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values: { oldPassword: string; newPassword: string }) => {
        setLoading(true);
        try {
            await changePassword({oldPassword: values.oldPassword, newPassword: values.newPassword});
            message.success('Пароль изменен успешно!');
            navigate('/dashboard');
        } catch (e: any) {
            message.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '50px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
            <h2>Change Password</h2>
            <Form name="change_password" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    rules={[{ required: true, message: 'Введите текущий пароль' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[{ required: true, message: 'Введите новый пароль' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ChangePasswordPage;
