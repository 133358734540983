import React, {useContext} from 'react';
import { Layout, Menu } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import AppHeader from '../components/header';
import AppFooter from '../components/footer';
import {UserContext} from "../contexts/userContext";

const { Content, Sider } = Layout;

const MainLayout: React.FC = () => {
    const location = useLocation();
    const user = useContext(UserContext);

    const menuItems = [
        { key: '1', path: '/dashboard', label: 'Главная' },
        { key: '2', path: '/items', label: 'Устройства' },
        { key: '3', path: '/management', label: 'Администрирование', adminOnly: true },
    ];

    const visibleMenuItems = menuItems.filter(
        (item) => !(item.adminOnly && user?.role !== 'ROLE_ADMIN')
    );

    const selectedKey =
        visibleMenuItems.find((item) => location.pathname.startsWith(item.path))?.key || '';

    return (
        <Layout style={{ minHeight: '100vh', flexDirection: 'column' }}>
            <AppHeader />
            <Layout style={{ flex: 1 }}>
                <Sider>
                    <Menu theme="dark" selectedKeys={[selectedKey]} mode="inline">
                        {visibleMenuItems.map((item) => (
                            <Menu.Item key={item.key}>
                                <Link to={item.path}>{item.label}</Link>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Sider>
                <Content style={{ padding: '40px' }}>
                    <Outlet />
                </Content>
            </Layout>
            <AppFooter />
        </Layout>
    );
};

export default MainLayout;