import React, { useContext } from 'react';
import {Layout, Avatar, Dropdown, Menu, Typography} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UserContext } from '../contexts/userContext';
import logo from '../assets/logo.png';
import {useNavigate} from "react-router-dom";

const { Header } = Layout;
const { Text } = Typography;

const AppHeader: React.FC = () => {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    if (!user) {
        return null;
    }

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleChangePassword = () => {
        navigate('/change-password');
    };

    const userMenu = (
        <Menu>
            <Menu.Item key="0">
                <Text>{user.email}</Text>
            </Menu.Item>
            <Menu.Item key="1">
                <Text>{user.department}</Text>
            </Menu.Item>
            <Menu.Item key="2">
                <Text>Mobile: {user.mobile}</Text>
            </Menu.Item>
            <Menu.Divider />
            {user?.role !== 'ROLE_ADMIN' && (
                <>
                    <Menu.Item key="3" onClick={handleChangePassword}>
                        <a>Изменить пароль</a>
                    </Menu.Item>
                    <Menu.Divider />
                </>
            )}
            <Menu.Item key="4" onClick={handleLogout}>
                <a>Logout</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Header
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: 'linear-gradient(90deg, #001F3F 0%, #005C97 100%)',
                padding: '0 40px',
                height: '100px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            </div>
            <Dropdown overlay={userMenu} trigger={['click']}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Avatar icon={<UserOutlined />} style={{ marginRight: '8px' }} />
                    <Text style={{ color: 'white' }}>{user.name}</Text>
                </div>
            </Dropdown>
        </Header>
    );
};

export default AppHeader;
