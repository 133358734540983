import api from './api';

export interface Alert {
    id: number;
    name: string;
    resource: string;
    event: string;
    open: boolean;
    severity: 'OK' | 'WARN' | 'ERROR';
    organizationId: string;
    duplicateCount: number;
    createdAt: string;
    closedAt: string;
    lastReceivedAt: string;
}

export interface AlertFilterRequestDto {
    name?: string;
    resource?: string;
    open?: boolean;
    severity?: 'OK' | 'WARN' | 'ERROR';
    expired?: boolean;
    organizationId?: string;
    limit?: number;
    offset?: number;
}

export const fetchAlerts = async (filters?: AlertFilterRequestDto): Promise<Alert[]> => {
    const response = await api.get('/alerts/filter', { params: filters });
    return response.data;
};

export const deleteAlert = async (id: number): Promise<void> => {
    await api.delete(`/alerts/${id}`);
};

export const closeAlert = async (id: number): Promise<void> => {
    await api.put(`/alerts/close/${id}`);
};
