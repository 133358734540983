import React from 'react';
import {List, Button, Spin, Typography, Popconfirm, Space} from 'antd';
import {DeleteOutlined, EditOutlined, ReloadOutlined} from '@ant-design/icons';
import { Organization } from '../services/organizations';

const { Text } = Typography;

interface OrganizationListProps {
    organizations: Organization[];
    loading: boolean;
    onReload: () => void;
    onEdit: (org: Organization) => void;
    onDelete: (id: string) => void;
}

const OrganizationList: React.FC<OrganizationListProps> = ({ organizations, loading, onReload, onEdit, onDelete }) => {
    return (
        <>
            <Button
                icon={<ReloadOutlined />}
                onClick={onReload}
                loading={loading}
                style={{ marginBottom: '10px' }}
            >
                Обновить
            </Button>
            {loading ? (
                <Spin />
            ) : (
                <List
                    bordered
                    dataSource={organizations}
                    renderItem={(org) => (
                        <List.Item key={org.uuid}>
                            <div>
                                <strong>{org.name}</strong>
                                <br/>
                                <Text type="secondary">Контактный email: {org.contactEmail ? org.contactEmail : "отсутствует"}</Text>
                                <br/>
                                <Text type="secondary">Создана: {new Date(org.createdAt).toLocaleString()}</Text>
                                <br/>
                                <Text type="secondary">Идентификатор: {org.uuid}</Text>
                            </div>
                            <Space>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => onEdit(org)}
                                >
                                    Изменить
                                </Button>
                                <Popconfirm
                                    title="Вы уверены, что хотите удалить организацию?"
                                    onConfirm={() => onDelete(org.uuid)}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button
                                        danger
                                        icon={<DeleteOutlined/>}
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
};

export default OrganizationList;
