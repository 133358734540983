import api from './api';
import qs from 'qs';

export interface Case {
    uuid: number;
    externalUuid: number;
    number: string;
    title: string;
    description: string;
    status: 'NEW' | 'REOPENED' | 'IN_PROGRESS' | 'WAITING_FOR_REACTION' | 'SOLVED' | 'CLOSED' | 'CANCELED';
    alertId: boolean;
    itemId: string;
    itemName: string;
    createdAt: string;
    updatedAt: string;
}

export interface CaseFilterRequestDto {
    title?: string;
    number?: string;
    itemName?: boolean;
    status?: string[];
    organizationId?: string;
    limit?: number;
    offset?: number;
}

export interface FetchCasesResponse {
    cases: Case[];
    total: number;
}

export const fetchCases = async (filters?: CaseFilterRequestDto): Promise<FetchCasesResponse> => {
    const response = await api.get('/cases', {
        params: filters,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    });
    return response.data;
};