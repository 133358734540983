import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/login';
import MainLayout from './pages/layout';
import { UserContext, User } from './contexts/userContext';
import Dashboard from "./pages/dashboard";
import ManagementPage from "./pages/management";
import RequireAuth from "./components/requireAuth";
import Items from "./pages/items";
import ItemPage from "./pages/itemPage";
import ChangePasswordPage from "./pages/changePassword";

const fetchUserData = async (): Promise<User | null> => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        return JSON.parse(storedUser);
    }
    return null;
};

const App: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        (async () => {
            const userData = await fetchUserData();
            setUser(userData);
        })();
    }, []);

    const handleLogin = (userData: User) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    return (
        <UserContext.Provider value={user}>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login onLogin={handleLogin} />} />
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <MainLayout />
                            </RequireAuth>
                        }
                    >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="items" element={<Items />} />
                        <Route path="items/:id" element={<ItemPage />} />
                        <Route path="management" element={<ManagementPage />} />
                        <Route path="/change-password" element={<ChangePasswordPage />} />
                        <Route index element={<Navigate to="dashboard" replace />} />
                    </Route>
                </Routes>
            </Router>
        </UserContext.Provider>
    );
};

export default App;
