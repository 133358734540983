import React, { useEffect, useState, useContext } from 'react';
import {Button, Typography, Modal, message, Space, Input, Select, Row, Col} from 'antd';
import {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import ItemForm from '../components/itemForm';
import ItemList from '../components/itemList';
import { ItemDetails, getAllItems } from '../services/itemService';
import {fetchOrganizations, Organization} from '../services/organizations';
import { UserContext } from '../contexts/userContext';

const { Title } = Typography;
const { Option } = Select;

const Items: React.FC = () => {
    const user = useContext(UserContext);

    const [items, setItems] = useState<ItemDetails[]>([]);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState<boolean>(false);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [nameFilter, setNameFilter] = useState<string>('');
    const [organizationFilter, setOrganizationFilter] = useState<string | undefined>(undefined);

    const loadItems = async (name?: string, organizationId?: string) => {
        setLoadingItems(true);
        try {
            const data = await getAllItems({
                limit: 50,
                offset: 0,
                name: name || undefined,
                organizationId: organizationId || undefined
            });
            setItems(data);
        } catch (error) {
            message.error('Не удалось загрузить устройства.');
        } finally {
            setLoadingItems(false);
        }
    };

    const loadOrganizations = async () => {
        setLoadingOrganizations(true);
        try {
            const data = await fetchOrganizations();
            setOrganizations(data);
        } catch (error) {
            message.error('Не удалось загрузить организации.');
        } finally {
            setLoadingOrganizations(false);
        }
    };

    useEffect(() => {
        loadItems(nameFilter, organizationFilter);
    }, []);

    useEffect(() => {
        if (user?.role === 'ROLE_ADMIN') {
            loadOrganizations();
        }
    }, [user]);

    const handleItemAdded = () => {
        loadItems(nameFilter, organizationFilter);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNameFilter(value);
    };

    const handleOrganizationFilterChange = (value: string | undefined) => {
        setOrganizationFilter(value);
    };

    const applyFilters = () => {
        loadItems(nameFilter, organizationFilter);
    };

    const handleReload = () => {
        loadItems(nameFilter, organizationFilter);
    };

    return (
        <div>
            <Title level={3}>Устройства</Title>

            <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                {user?.role === 'ROLE_ADMIN' && (
                <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={showModal}
                    >
                        Создать
                    </Button>
                </Col>
                )}
                <Col>
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={handleReload}
                        loading={loadingItems}
                    >
                        Обновить
                    </Button>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Input
                        placeholder="Фильтр по имени"
                        value={nameFilter}
                        onChange={handleNameFilterChange}
                        onPressEnter={applyFilters}
                        allowClear
                    />
                </Col>

                {user?.role === 'ROLE_ADMIN' && (
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Select
                            placeholder="Выберите организацию"
                            allowClear
                            loading={loadingOrganizations}
                            value={organizationFilter}
                            onChange={handleOrganizationFilterChange}
                            style={{ width: '100%' }}
                        >
                            <Option value={null}>Все</Option>
                            {organizations.map(org => (
                                <Select.Option key={org.id} value={org.id}>
                                    {org.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                )}

                <Col xs={24} sm={24} md={8} lg={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button type="primary" onClick={applyFilters}>
                        Применить фильтр
                    </Button>
                </Col>
            </Row>

            <ItemList
                items={items}
                loading={loadingItems}
                onReload={loadItems}
            />
            {user?.role === 'ROLE_ADMIN' && (
                <Modal
                    title="Создать новое устройство"
                    visible={isModalVisible}
                    footer={null}
                    onCancel={handleCancel}
                    destroyOnClose
                >
                    <ItemForm
                        organizations={organizations}
                        loadingOrganizations={loadingOrganizations}
                        onItemAdded={() => {
                            handleItemAdded();
                            setIsModalVisible(false);
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Items;
