import React from 'react';

export interface User {
    uuid: string;
    name: string;
    email: string;
    department: string;
    mobile: string;
    role: string;
    organizationId: string;
}

export const UserContext = React.createContext<User | null>(null);
