import React from 'react';
import { Layout, Typography } from 'antd';
import footer_logo from '../assets/footer_logo.png';

const { Footer } = Layout;
const { Text } = Typography;

const AppFooter: React.FC = () => (
    <Footer style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'linear-gradient(90deg, #001F3F 0%, #005C97 100%)',
        padding: '40px 40px',
        color: 'white'
    }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={footer_logo} alt="Footer Logo" style={{ height: '250px', marginRight: '20px' }} />
            <Text style={{ color: 'white', fontSize: 20 }}>© ООО «Паладин Энтерпрайз» 1994−2024</Text>
        </div>
    </Footer>
);

export default AppFooter;
