import React from 'react';
import {List, Button, Spin, Typography, message, Space, Popconfirm} from 'antd';
import {DeleteOutlined, EditOutlined, ReloadOutlined} from '@ant-design/icons';
import {User} from "../contexts/userContext";
import {deleteUser} from "../services/customerService";

const { Text } = Typography;

interface CustomerListProps {
    customers: User[];
    loading: boolean;
    onReload: () => void;
    onEdit: (user: User) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({customers, loading, onReload, onEdit}) => {
    const handleDelete = async (email: string) => {
        try {
            await deleteUser({ email });
            message.success('Пользователь успешно удален.');
            onReload();
        } catch (error) {
            message.error('Не удалось удалить пользователя.');
        }
    };

    return (
        <>
            <Button
                icon={<ReloadOutlined />}
                onClick={onReload}
                loading={loading}
                style={{ marginBottom: '10px' }}
            >
                Refresh Customers
            </Button>
            {loading ? (
                <Spin />
            ) : (
                <List
                    bordered
                    dataSource={customers}
                    renderItem={(customer) => (
                        <List.Item key={customer.uuid}>
                            <div style={{width: '100%'}}>
                                <Space direction="vertical" style={{width: '100%'}}>
                                    <strong>{customer.email}</strong>
                                    <Text type="secondary">Имя: {customer.name}</Text>
                                    <Text type="secondary">Телефон: {customer.mobile}</Text>
                                    <Text type="secondary">Организация: {customer.organizationId}</Text>
                                </Space>
                            </div>
                            <Space>
                                <Button
                                    icon={<EditOutlined/>}
                                    onClick={() => onEdit(customer)}
                                >
                                    Изменить
                                </Button>
                                <Popconfirm
                                    title="Вы уверены, что хотите удалить этого пользователя?"
                                    onConfirm={() => handleDelete(customer.email)}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button
                                        danger
                                        icon={<DeleteOutlined/>}
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
};

export default CustomerList;
